import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import axios from "axios";

/* import { Modal } from "@redq/reuse-modal"; */
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";

import Navbar from "Containers/Navbar";

import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import { graphql, StaticQuery } from "gatsby";
import Testimonial from "Containers/Testimonial";
/* import Payment from "Containers/Payment"; */
import Footer from "Containers/Footer";
import { proceedPayment, paymePromise } from "../../common/components/payment";
/* import { openModal, closeModal } from "@redq/reuse-modal"; */
import CheckCoupon from "../../common/components/CheckCoupon";
//import { navigate } from "@reach/router";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import Box from "common/components/Box";
import SectionWrapper from "../../Containers/Payment/service.style";
import Container from "common/components/UI/Container";
import ScrollSomeItems from "../../Containers/ScrollSomeItems";
import StrongerPayment from "../../Containers/StrongerPayment";

const SaasClassic = ({ location, Row, Col }) => {
  const [Tour, setTour] = React.useState();
  const [lang, setLang] = React.useState("He");

  const [reviews, setReviews] = React.useState();
  const [paymeURL, setPaymeURL] = React.useState(null);
  const [source, setSource] = React.useState("Dguide");

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const lang = Tour.language === "Hebrew" ? "He" : "En";
      const coupon = couponText ? couponText : "NaN";
      const status_code = details.status;
      const buyer_name = `${details.payer.name.given_name}  ${details.payer.name.surname}`;
      const price = `${details.purchase_units[0].amount.value} ${details.purchase_units[0].amount.currency_code}`;
      const sale_created = details.create_time;
      const buyer_email = details.payer.email_address;
      const current = new Date();
      const activateCoupon = `${Tour.id.substring(5, 8)}${current.getMonth()}${
        (current.getDate() + current.getMonth() + current.getFullYear() + 7) % 5
      }`;
      try {
        const callBack = `https://europe-central2-tours-app-1579553856346.cloudfunctions.net/callbackpaymeweb/?coupon=${coupon}&aCoupon=${activateCoupon}&locale=${lang}&tID=${Tour.id}&source=${source}&guideId=${Tour.tour_guide.id}&buyer_name=${buyer_name}&price=${price}&sale_created=${sale_created}&status_code=${status_code}&buyer_email=${buyer_email}`;
        const successLink = `https://dguidetours.com/returnPayment/?tID=${Tour.id}&coupon=${coupon}&aCoupon=${activateCoupon}&bitCheck=6612&source=${source}&lang=${lang}&guideId=${Tour.tour_guide.id}&totalA=${Tour.price}`;
        // console.log("Return URL", successLink);
        axios
          .get(callBack, {
            // Add parameters here
          })
          .then((response) => {
            console.log(response.data);
            navigate(successLink);
            // Handle data
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("CANT CREATE LINK", error);
      }
    });
  };

  const textLang = {
    topTitle: {
      He: "סיכום הזמנה",
      En: "Order Summary",
    },
    mainTitle: {
      He: "צעד אחרון לפני שתתחילו לטייל",
      En: "Lest step before starting travle",
    },

    cartSummery: {
      He: "סיכום הזמנה",
      En: "Order Summary",
    },
    price: {
      He: "המחיר",
      En: "The Price",
    },
    creditButton: {
      He: "שלם באצמצעות כרטיס אשראי",
      En: "Pay By Credit card",
    },
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    let source_id = params.get("source");
    if (!source_id) {
      source_id = "Dguide";
    }

    setSource(source_id);

    async function getParams() {
      if (Tour) {
        if (Tour.language === "Hebrew") {
          setLang("He");
        } else {
          setLang("En");
        }
        try {
          await proceedPayment(Tour, setPaymeURL, source);
        } catch (error) {
          console.log("ERROR", error);
        }
      }
    }
    if (Tour) getParams();
  }, [Tour]);

  /*ADD FOR COUPON START*/
  const [couponText, setCouponText] = React.useState(null);
  const setPrice = async (newPrice, coupon = "NaN") => {
    Tour.price = newPrice;
    try {
      paymePromise(Tour, source, coupon).then((urlRes) => {
        setPaymeURL(urlRes);
      });
    } catch (error) {
      console.log("Can't Promise", error);
    }
  };

  const ModalCoupon = () => {
    return (
      <CheckCoupon
        setPrice={setPrice}
        price={Tour.price}
        setCouponText={setCouponText}
        couponText={couponText}
        t_id={Tour.id}
        source_id={source}
        lang={lang}
      />
    );
  };
  /*ADD FOR COUPON END*/

  return (
    <StaticQuery
      query={graphql`
        query orR8LG1DR1LDaTZ9it7wCart {
          allTour(filter: { id: { eq: "orR8LG1DR1LDaTZ9it7w" } }) {
            nodes {
              tour_image_obj {
                url
              }
              id
              location
              language
              main_sentense
              price
              rating {
                total
                votes
              }
              title
              tour_guide {
                email
                full_name
                id
                profile_image
              }
              hostedBy
            }
          }
          allReviews {
            nodes {
              content
              id
              name
              time
              rating
              imgurl
              lang
            }
          }
        }
      `}
      render={(data) => {
        setReviews(data.allReviews.nodes);

        setTour(data.allTour.nodes[0]);
        if (!Tour || !reviews) return null;
        return (
          <>
            <ThemeProvider theme={saasClassicTheme}>
              <Fragment>
                <Seo
                  paypalScript={true}
                  title={`Dguide cart | ${Tour.title}`}
                  description={Tour.description}
                  lang={Tour.language}
                  meta={[
                    "סיורים",
                    "סיורים בירושלים",
                    "טיול בירושלים",
                    "טיול",
                    "טיול למשפחה",
                    "מקומות לטייל בשבת",
                    "איפה אפשר לטייל",
                    "המלצות לטיולים",
                    "טיול יום",
                    "סיור יום",
                    "אטרקציות בירושלים",
                    "סיור עצמאי",
                    "מדריך טיולים",
                    "יום כיף",
                    "מה לעשות בירושלים",
                    "סיורים מודרכים בירושלים",
                  ]}
                  keywords={[
                    "סיור מודרך",
                    "סיור עצמאי",
                    `מה אפשר לעשות בםלורנטין`,
                    "מה אפשר לעשות בתל אביב",
                    "גרפיטי בפלורנטין",
                    "סיור גרפיטי בילולנטין",
                    "סיור גרפיטי בתל אביב",
                  ]}
                />
                {/*   <Modal /> */}
                <ResetCSS />
                <GlobalStyle />

                <ContentWrapper
                  style={{
                    backgroundColor: "#f9f9f9f9",
                  }}
                >
                  <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    {couponText && (
                      <div
                        style={{
                          textAlign: "center",
                          margin: "3px",
                          fontWeight: "550",
                        }}
                      >
                        {couponText}
                      </div>
                    )}
                    <DrawerProvider>
                      <Navbar handleCouponModal={null} returnPayment={true} />
                    </DrawerProvider>
                  </Sticky>
                  <SectionWrapper
                    style={{
                      direction: Tour.language === "He" ? "rtl" : "ltr",
                    }}
                  >
                    <Container className="containerPayment">
                      <Box {...Row} className="rowBox">
                        <Box
                          className="colBox"
                          {...Col}
                          key={`service-item-${1}`}
                        >
                          <ScrollSomeItems
                            item={Tour}
                            paymeURL={paymeURL}
                            onApprovePP={onApprove}
                            ModalCoupon={ModalCoupon}
                            trailer={
                              "https://www.youtube.com/embed/sVtYAiIjKJ0"
                            }
                          />
                        </Box>
                        <Box
                          className="colBox"
                          {...Col}
                          key={`service-item-${2}`}
                        >
                          <StrongerPayment item={Tour} reviews={reviews} />
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingTop: "50px",
                            }}
                          >
                            <Testimonial allReviews={reviews} lang={lang} />
                          </div>
                        </Box>
                      </Box>
                    </Container>
                  </SectionWrapper>
                  <Footer />
                </ContentWrapper>
              </Fragment>
            </ThemeProvider>
          </>
        );
      }}
    />
  );
};
SaasClassic.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  Row: PropTypes.object,
  Col: PropTypes.object,
  serviceTitleStyle: PropTypes.object,
  secDes: PropTypes.object,
  /*  button: PropTypes.object, */
};

SaasClassic.defaultProps = {
  secTitleWrapper: {
    mb: ["30px", "40px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "16px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#E14A1B", // "#ff4362",
    mb: "12px",
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["20px", "24px", "36px", "36px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    ml: "auto",
    mr: "auto",
    lineHeight: "1.12",
    width: "540px",
    maxWidth: "100%",
  },
  Row: {
    flexBox: true,
    /* flexWrap: "wrap", */
    justifyContent: "center",
    flexDirection: "row",
    ml: ["0", "-15px", "-20px", "-35px", "-35px"],
    mr: ["0", "-15px", "-20px", "-35px", "-35px"],
  },
  Col: {
    width: ["50%", "50%", "50%", "50%", "50%"],
    pl: ["15px", "15px", "25x", "35px", "35px"],
    pr: ["15px", "15px", "25px", "35px", "35px"],
    mb: "0px",
  },
  serviceTitleStyle: {
    fontSize: ["17px", "17px", "17px", "18px", "19px"],
    fontWeight: "500",
    color: "#E14A1B", //"#ff4361",
    mb: 0,
    textAlign: "center",
  },
  secDes: {
    fontSize: ["14px", "15px", "16px"],
    color: "#5d646d",
    lineHeight: "1.875",
    mt: "30px",
    ml: "auto",
    mr: "auto",
    width: "470px",
    maxWidth: "100%",
    textAlign: "center",
  },
};
export default SaasClassic;
